import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { saasTheme } from 'common/src/theme/saas';
import { ResetCSS } from 'common/src/assets/css/style';
import { GlobalStyle, ContentWrapper } from '../containers/Saas/saas.style';
import Navbar from '../containers/Saas/Navbar';
import BannerSection from '../containers/Saas/BannerSection';
import FeatureSection from '../containers/Saas/FeatureSection';
import Footer from '../containers/Saas/Footer';
import PricingSection from '../containers/Saas/PricingSection';
import TimelineSection from '../containers/Saas/TimelineSection';
import PartnerSection from '../containers/Saas/PartnerSection';
import SEO from '../components/seo';
import util from '../services/util';
import ConceptSection from '../containers/Saas/ConceptSection';
import SupportTypeSection from '../containers/Saas/SupportTypeSection';
import ServiceFlowSection from '../containers/Saas/ServiceFlowSection';
import ScrollUpButton from 'react-scroll-up-button';
import 'react-responsive-modal/styles.css';
import './index.css';
import ScrollUp from 'common/src/assets/image/saas/scrollup.svg';
import { Link } from 'gatsby';

export default () => {
  util.lang = 'ko';
  return (
    <ThemeProvider theme={saasTheme}>
      <Fragment>
        <SEO
          title={util.label('seo', 'title')}
          description={util.label('seo', 'description')}
          lang={util.lang}
          keywords={util.label('seo', 'keywords')}
        />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <Navbar />
          </Sticky>
          <BannerSection />
          <ServiceFlowSection />
          <ConceptSection />
          <TimelineSection />
          <FeatureSection />
          <SupportTypeSection />
          {/*<PricingSection />*/}
          <PartnerSection />
          <Footer />
        </ContentWrapper>
        <ScrollUpButton
          ContainerClassName="scroll-up-container"
          TransitionClassName="scroll-up-transition"
        >
          <img src={ScrollUp} alt={'Up'} width={42} height={42} />
        </ScrollUpButton>
        <Link to={'/contact'}>
          <div className={'demo-aside-button'}>
            <span
              style={{
                fontSize: 13,
                writingMode: 'vertical-rl',
                textOrientation: 'upright',
              }}
            >
              DEMO
            </span>
          </div>
        </Link>
      </Fragment>
    </ThemeProvider>
  );
};
